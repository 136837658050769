* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #36454F;
    color: #008080;
    font-family: 'Ahsing Regular', serif;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.header nav ul {
    list-style: none;
    display: flex;
    gap: 30px;
    margin: 0;
    padding: 0;
}

.header nav ul li a {
    text-decoration: none;
    color: #008080;
    gap: 30px;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 5px;
    transition: color 0.3s, background-color 0.3s, transform 0.3s;
}

@media (max-width: 768px) {
    .header {
        flex-direction: column;
        padding: 40px 40px;
    }

    .header nav ul {
        flex-direction: column;
        gap: 40px;
    }
}