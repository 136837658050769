@font-face {
  font-family: 'Ahsing Regular';
  src: url('./fonts/Ahsing-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Duke Charming Regular';
  src: url('./fonts/Duke Charming DEMO.otf') format('opentype');
}

@font-face {
  font-family: 'Architype Renner Medium Bold';
  src: url('./fonts/ArchitypeRenner-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'Architype Renner Medium';
  src: url('./fonts/ArchitypeRenner-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'P22 Underground';
  src: url('./fonts/P22Underground-PCp.otf') format('opentype');
}
@font-face {
  font-family: 'Flamante Sans Bold';
  src: url('./fonts/flamante-sans.bold.ttf') format('opentype');
}

body {
  margin: 0;
  background-color: #000000;
  font-family: 'Flamante Sans Bold', serif;
  animation: fadeInPage 2.2s ease-in-out;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, p {
  margin: 0;
}

.App {
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.header {
  display: flex;
  justify-content: space-between; /* Aligns icons on the left and nav on the right */
  align-items: center;
  padding: 10px 0;
  background-color: #000000;
  color: #ffffff;
  font-family: 'Roboto', serif;
  width: 100%;
  position: relative;
  z-index: 1000;
}

.social-icons {
  display: flex;
  gap: 15px; /* Space between icons */
  padding-left: 20px; /* Add some padding on the left to separate icons from the edge */
}

.social-icons a {
  color: #999999;
  font-size: 2rem; /* Smaller size for icons */
}
.social-icons a:hover {
  transform: scale(1.5); /* Adjusted scale to 1.5 */
  transition: transform 0.3s;
}

.nav-container {
  padding: 30px 30px;
  border-bottom-left-radius: 40px;
  display: flex;
  margin-top: -10px;
  /* Ensures the nav container stretches */
  justify-content: flex-end; /* Aligns the navigation links to the right */
}

.header nav ul {
  font-family: 'Flamante Sans Bold', serif;
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.header nav ul li a {
  font-family: 'Flamante Sans Bold', serif;
  text-decoration: none;
  color: #999999;
  font-size: 1rem;
  padding: 10px 15px;
  transition: color 0.3s, transform 0.3s;
}

.header nav ul li a:hover {
  color: #ffffff;
}
.nav-container {
  display: flex;
  align-items: center;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-right: 20px;
}

.menu-icon span {
  background: #ffffff;
  border-radius: 2px;
  height: 3px;
  margin: 4px 0;
  width: 25px;
  transition: 0.3s;
}

.nav-menu {
  display: flex;
}

.nav-menu ul {
  font-family: 'Roboto', serif;
  list-style: none;
  display: flex;
  gap: 40px;
  margin: 0;
  padding: 0;
}

.nav-menu ul li a {
  text-decoration: none;
  color: #999999;
  font-size: 1.25rem;
  padding: 10px 15px;
  transition: color 0.3s, transform 0.3s;
}

.nav-menu ul li a:hover {
  color: #ffffff;
  transform: scale(1.5);
  transition: transform 0.3s;
}
.download-icon {
  display: none;
  margin-left: 8px; /* Space between text and icon */
}

.resume-link a:hover .download-icon {
  display: inline-block;
}
/* Mobile styles */
@media (max-width: 768px) {
  .header {
    flex-direction: row;
    padding: 15px 20px;
    text-align: center;
  }

  .menu-icon {
    display: flex;
  }

  .nav-menu {
    position: absolute;
    top: -400px;
    left: 0;
    right: 0;
    background-color: #1c1c1c;
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: top 0.3s ease-in-out;
  }

  .nav-menu.open {
    top: 80px; /* Adjust to the height where the menu should appear */
    padding-top: 20px; /* Add padding above the menu */
    padding-bottom: 20px; /* Add padding below the menu */
  }

  .nav-menu ul {
    flex-direction: column;
    margin-top: 10px;
    gap: 20px;
  }

  .nav-menu ul li {
    padding-top: 8px; /* Add space above each item */
    padding-bottom: 8px; /* Add space below each item */
  }

  .nav-menu ul li a {
    font-size: 1rem;
    padding: 8px 10px;
  }
  .download-icon {
    display: none;
    margin-left: 5px;
  }

  .resume-link a:hover .download-icon {
    display: inline-block;
  }
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #000000;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  scroll-margin-top: 150px;
  position: relative;
  overflow: hidden;
}

.about-me-title {
  color: #adadac;
  font-family: 'Flamante Sans Bold', serif;
  font-size: 2rem;
  margin-bottom: 20px; /* Add some spacing between the title and the rectangle */
}
.about-me-title::after {
  content: '';
  width: 50px;
  height: 4px;
  background-color: #adadac;
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}
.hero-content {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #1c1c1c, #2e2e2e);
  padding: 30px 50px; /* Slightly bigger */
  border-radius: 20px;
  max-width: 800px; /* Adjusted width */
  max-height: 800px;
}

.hero-image-container {
  flex-shrink: 0;
  position: relative;
  width: 190px; /* Adjusted size */
  height: 190px; /* Adjusted size */
  overflow: hidden;
  margin-right: 30px;
}

.hero-image-container img {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Circular border */
}

.hero-text {
  max-width: 700px; /* Adjusted width */
  text-align: left;
  color: #adadac;
  font-family: 'Flamante Sans Bold', serif;
  font-size: 1rem; /* Slightly bigger and more relaxed font size */
  line-height: 1.6;
}

.hero-text h3 {
  margin: 0;
  font-size: 1.5rem;
  color: #ffffff;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-height: none; /* Remove max-height restriction */
  }

  .about-me-title {
    font-size: 1.5rem; /* Adjust font size for better visibility */
  }

  .hero-content {
    flex-direction: column;
    align-items: center;
    padding: 20px;
    max-width: 100%; /* Make sure it fits within the mobile screen */
    border-radius: 10px; /* Adjust border radius for smaller screens */
  }

  .hero-image-container {
    width: 140px; /* Adjusted size */
    height: 140px; /* Adjusted size */
    margin-right: 0;
    margin-bottom: 20px;
  }

  .hero-text {
    text-align: center;
    font-size: 1.2rem; /* Slightly bigger font size for better readability */
    margin-bottom: 20px; /* Adjust margin between sections */
  }

  .hero-text h3 {
    font-size: 1.2rem; /* Adjusted size */
  }

  .hero-description {
    padding: 10px; /* Adjust padding inside the box */
    margin-bottom: 20px; /* Adjust margin between sections */
  }

  .skills-section {
    margin-top: 20px; /* Adjust margin between sections */
  }
}
.services {
  padding: 60px 20px;
  background-color: #000000;
  color: #adadac;
  margin: 40px 20px;
  border-radius: 10px;
  text-align: center;
  scroll-margin-top: 150px;
  font-family: "Flamante Sans Bold", serif;
}

.services h2 {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 2rem;
  color: #adadac;
  position: relative;
}

.services h2::after {
  content: '';
  width: 50px;
  height: 4px;
  background-color: #adadac;
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

.projects-content {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

@media (min-width: 540px) {
  .projects-content {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 960px) {
  .projects-content {
    grid-template-columns: repeat(3, 1fr);
  }
}

.project-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  height: 400px;
  list-style: none;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
}

.project-card::before {
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.project-card__background {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 200ms linear;
  filter: blur(12px);
}

.project-card:hover .project-card__background {
  transform: scale(1.05);
  filter: blur(6px);
}

.project-card__content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
  z-index: 2;
}

.project-card__category {
  color: #ffffff;
  font-size: 0.9rem;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.project-card__heading {
  color: #ffffff;
  font-size: 1.5rem;
  text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.2);
  line-height: 1.4;
}

.project-card__description {
  color: #ffffff;
  margin: 0;
  line-height: 1.6;
}

.projects-content:hover > .project-card:not(:hover) {
  filter: blur(10px);
}

@media (max-width: 768px) {
  .services {
    padding: 40px 10px;
    margin: 20px 10px;
  }

  .services h2 {
    font-size: 1.8rem;
  }

  .project-card {
    margin: 15px 0;
  }

  .project-card__heading {
    font-size: 1.3rem;
  }

  .project-card__description {
    font-size: 1.1rem;
  }
}

.projects {
  padding: 60px 20px;
  background-color: #000000;
  color: #adadac;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 40px 20px;
  border-radius: 10px;
  text-align: center;
  scroll-margin-top: 150px;
  font-family: "Flamante Sans Bold", serif;
}

.projects h2 {
  text-align: center;
  margin-bottom: 40px;
  font-weight: 700;
  font-size: 2rem;
  color: #adadac;
  position: relative;
}

.projects h2::after {
  content: '';
  width: 50px;
  height: 4px;
  background-color: #adadac;
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

.carousel-container {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}

.slide-container {
  position: relative;
}

.slide-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.text-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 1);
  color: #fff;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  z-index: 2;
  width: 80%;
}

.text-container h3 {
  margin-bottom: 10px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.5rem;
  z-index: 3;
  transition: background-color 0.3s ease, transform 0.3s ease;
}


.arrow-prev {
  left: 10px; /* Adjust to position within the picture */
}

.arrow-next {
  right: 10px; /* Adjust to position within the picture */
}

.difficulty-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  bottom: 30px;
  right: 30px;
  background-color: #adadac;
  z-index: 3;
}

.difficulty-circle.beginner {
  background-color: #4CAF50; /* Green */
}

.difficulty-circle.intermediate {
  background-color: #FF9800; /* Orange */
}

.difficulty-circle.advanced {
  background-color: #F44336; /* Red */
}

@media (max-width: 768px) {
  .projects {
    padding: 40px 10px;
    margin: 20px 10px;
  }

  .projects h2 {
    font-size: 1.8rem;
    margin-top: 30px;
  }

  .carousel-container {
    width: 100%;
  }

  .text-container {
    font-size: 0.9rem;
    padding: 10px;
  }

  .arrow {
    font-size: 1.2rem;
    padding: 8px;
  }

  .difficulty-circle {
    bottom: 3px;
    right: 15px;
  }
}
.skills {
  padding: 40px;
  background-color: #000000;
  font-family: 'Duke Charming Regular', serif;
  color: #adadac;
  scroll-margin-top: 150px;
  position: relative;
}

.skills h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  font-family: "Flamante Sans Bold", serif;
}

.skills h2::after {
  content: '';
  width: 50px;
  height: 4px;
  background-color: #adadac;
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

.skills-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  position: relative;
  font-family: Roboto, serif;
}

.circle {
  margin-top: 50px;
  position: relative;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill-container {
  position: absolute;
  text-align: center;
  transition: transform 0.3s ease;
}

.skill-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle-bg {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid #adadac;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.circle-fill {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(255, 184, 37, 0.8); /* Initial background color with opacity */
  transition: height 0.3s ease, background-color 0.3s ease;
  height: 0; /* Initial height is 0 */
  z-index: 2; /* Ensure the fill covers the icon */
}

.skill-icon {
  font-size: 2rem;
  color: #ffffff;
  position: relative;
  z-index: 1;
  transition: opacity 0.3s ease; /* Transition for opacity */
}

.skill-percent {
  position: absolute;
  bottom: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, 50%); /* Adjust position */
  font-size: 1.5rem; /* Bigger font size */
  color: #ffffff;
  font-weight: bold; /* Bold text */
  z-index: 3; /* Ensure the percentage is above the fill */
  opacity: 0; /* Initially hidden */
  transition: opacity 0.3s ease;
}

.skill-description {
  position: absolute;
  background-color: rgba(54, 53, 53, 1); /* Solid background color */
  padding: 3px 6px; /* Smaller padding */
  border-radius: 5px;
  color: #adadac;
  font-size: 1.2rem; /* Smaller font size */
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  font-weight:bold;
  font-family: "Flamante Sans Bold", serif;
}

.skill-container:nth-child(6) .skill-description {
  left: -110px; /* Position to the left for JavaScript circle */
  top: 50%;
  transform: translateY(-50%);
}

.skill-container:nth-child(1) .skill-description {
  left: 97px; /* Position to the right for CSS circle */
  top: 40px;
  transform: translateY(-50%);
}
.skill-container:nth-child(9) .skill-description {
  left: 8px; /* Position to the right for CSS circle */
  top: -20px;
  transform: translateY(-50%);
}
.skill-container:nth-child(8) .skill-description {
  left: 12px; /* Position to the right for CSS circle */
  top: -20px;
  transform: translateY(-50%);
}
.skill-container:nth-child(10) .skill-description {
  left: 12px; /* Position to the right for CSS circle */
  top: -20px;
  transform: translateY(-50%);
}
.skill-container:nth-child(7) .skill-description {
  left: 5px; /* Position to the right for CSS circle */
  top: -20px;
  transform: translateY(-50%);
}
.skill:hover .circle-fill {
  height: var(--skill-level); /* Fill up the circle on hover */
  background-color: rgba(255, 184, 37, 1); /* Solid background color on hover */
}

.skill:hover .skill-percent {
  opacity: 1; /* Show percentage on hover */
}

.skill:hover .skill-description {
  opacity: 1;
}

.skill:hover .skill-icon {
  opacity: 0; /* Hide the icon on hover */
}

@media (max-width: 768px) {
  .skills {
    padding: 20px;
    margin-bottom: 40px;
  }

  .skills h2 {
    font-size: 1.8rem;
    margin-bottom: 70px;
  }

  .skills-content {
    font-size: 1rem; /* Reduced font size */
    height: 250px; /* Reduced height */
  }

  .circle {
    width: 50px; /* Adjusted size */
    height: 50px; /* Adjusted size */
  }

  .skill-container {
    transform: rotate(0deg) translate(2.5em) rotate(0deg); /* Adjusted translate value */
  }

  .skill {
    width: 40px;
    height: 40px;
  }

  .skill-icon {
    font-size: 1.2rem;
  }

  .skill-description {
    top: 70px;
  }

  .skill-percent {
    font-size: 0.8rem; /* Slightly smaller font size for mobile */
    font-weight: bold;
  }
  .skill-container:nth-child(1) .skill-description {
    left: -55px; /* Position to the right for CSS circle */
    top: 50%;
    transform: translateY(-50%);
  }
  .skill-container:nth-child(6) .skill-description {
    left: 50px; /* Position to the left for JavaScript circle */
    top: 50%;
    transform: translateY(-50%);
  }
  .skill-container:nth-child(7) .skill-description {
    left: -12px; /* Position to the right for CSS circle */
    top: -40px;
    transform: translateY(-50%);
  }
  .skill-container:nth-child(10) .skill-description {
    left: -20px; /* Position to the right for CSS circle */
    top: -40px;
    transform: translateY(-50%);
  }
  .skill-container:nth-child(8) .skill-description {
    left: -10px; /* Position to the right for CSS circle */
    top: -30px;
    transform: translateY(-50%);
  }
  .skill-container:nth-child(9) .skill-description {
    left: -10px; /* Position to the right for CSS circle */
    top: -30px;
    transform: translateY(-50%);
  }

}

.footer {
  padding-top: 10px;
  padding-bottom: 100px;/* Reduce the padding at the top of the footer */
  margin-top: 80px; /* Ensure there is no margin at the top of the footer */
  background-color: #000000;
  color: #adadac;
  font-family: 'Flamante Sans Bold', serif;
  text-align: center;
}

/* Additional footer styles */
.footer h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.footer h2::after {
  content: '';
  width: 50px;
  height: 4px;
  background-color: #adadac;
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

.footer p {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Contact Info */
.contact-info {
  margin-bottom: 20px;
}

.contact-info p {
  margin: 5px 0;
}

.contact-info .highlight {
  color: #00d1b2; /* Highlight color */
  text-decoration: underline;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  max-width: 600px;
  margin: 0 auto;

}

.input-group {
  display: flex;
  gap: 20px;
  width: 100%;

}

.input-group input {
  flex: 1;
  padding: 15px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #1c1c1c;
  color: #adadac;
  font-size: 1rem;
  font-family: 'Duke Sans', sans-serif;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 15px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #1c1c1c;
  color: #adadac;
  font-size: 1rem;
  font-family: 'Duke Sans', sans-serif;
  font-weight:bold;
  resize: none;
}

/* Autofill styles */
.contact-form input:-webkit-autofill,
.contact-form textarea:-webkit-autofill {
  background-color: #1c1c1c !important;
  color: #adadac !important;
  border: 1px solid #444 !important;
  -webkit-box-shadow: 0 0 0 30px #1c1c1c inset !important;
  -webkit-text-fill-color: #adadac !important;
}

.contact-form input:-webkit-autofill:focus,
.contact-form textarea:-webkit-autofill:focus {
  background-color: #1c1c1c !important;
  color: #adadac !important;
  border: 1px solid #444 !important;
  -webkit-box-shadow: 0 0 0 30px #1c1c1c inset !important;
  -webkit-text-fill-color: #adadac !important;
}
.link-icon {
  margin-left: 8px; /* Adds space between "Resume" and the icon */
  vertical-align: middle; /* Ensures the icon aligns with the text */
}
/* Modern button styles */
.contact-form button {
  position: relative;
  overflow: hidden;
  width: 7rem;
  color: #adadac;
  border: 2px solid #adadac;
  background-color: transparent;
  cursor: pointer;
  line-height: 2;
  margin: 0;
  padding: 0;
  border-radius: 1.5rem;
  font-size: 1.125rem;
  outline: none;
  transition: transform .125s;
  font-family: 'Duke Sans', sans-serif;
  font-weight:bold;
}
.contact-form button:hover {
  position: relative;
  overflow: hidden;
  width: 7rem;
  color: #000;
  border: 2px solid #adadac;
  background-color: #adadac;
  cursor: pointer;
  line-height: 2;
  margin: 0;
  padding: 0;
  border-radius: 1.5rem;
  font-size: 1.125rem;
  outline: none;
  font-family: 'Duke Sans', sans-serif;
  font-weight:bold;
  transition: color 0.75s, background-color 0.75s;
}
.contact-form button:active {
  transform: scale(.9, .9);
}

.contact-form button:before,
.contact-form button:after {
  position: absolute;
  opacity: 0;
  border-radius: 50%;
  background-color: #fff;
  top: 50%;
  left: 50%;
  margin-top: -1.125rem;
  margin-left: -1.125rem;
  width: 2.25rem;
  height: 2.25rem;
  content: '';
  z-index: 1;
}

.contact-form button.loading {
  animation: button-loading .5s forwards;
}

.contact-form button.loading:before {
  opacity: 1;
  animation: button-dot-intro .5s forwards;
}

.contact-form button.loading:after {
  opacity: 0;
  animation: button-dot-pulse 1.5s infinite .5s;
}

.contact-form button.ready {
  text-indent: 0;
  color: transparent;
  background-color: #adadac;
  animation: button-ready .333s forwards;
}

.contact-form button.ready:before {
  position: absolute;
  left: 0;
  right: 0;
  top: auto;
  margin: 0;
  width: auto;
  height: auto;
  border-radius: 0;
  background-color: transparent;
  color: #8cce1e;
  content: 'ready';
  opacity: 0;
  z-index: 2;
  animation: button-ready-label .5s forwards .275s;
}

.contact-form button.ready:after {
  opacity: 1;
  animation: button-dot-outro .333s;
}

@keyframes button-loading {
  0% {}
  20% {
    color: transparent;
    transform: scale(1, 1);
  }
  40% {
    border-color: #adadac;
    background-color: transparent;
    transform: scale(1, 1);
  }
  60% {
    transform: scale(.7, 1.1);
    margin-left: 1.25rem;
    width: 2.5rem;
    text-indent: -0.6125rem;
    color: transparent;
    border-color: #adadac;
    background-color: #adadac;
  }
  80% {
    transform: scale(1, 1);
  }
  100% {
    margin-left: 1.25rem;
    width: 2.5rem;
    background-color: #adadac;
    border-color: #adadac;
    color: transparent;
  }
}

@keyframes button-dot-intro {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
    transform: scale(1, 1);
  }
  100% {
    transform: scale(.75, .75);
  }
}

@keyframes button-dot-pulse {
  0% {
    opacity: 1;
    transform: scale(.75, .75);
  }
  15% {
    transform: scale(.85, .85);
  }
  45% {
    transform: scale(.75, .75);
  }
  55% {
    transform: scale(.95, .95);
  }
  85% {
    transform: scale(.75, .75);
  }
  100% {
    opacity: 1;
    transform: scale(.75, .75);
  }
}

@keyframes button-ready {
  0% {
    margin-left: 1.25rem;
    width: 2.5rem;
  }
  10% {
    background-color: #adadac;
    border-color: #adadac;
  }
  70% {
    margin: 0;
    width: 7.25rem;
    background-color: #fff;
    transform: scale(1.1, 1.1);
  }
  100% {
    margin: 0;
    width: 7rem;
    border-color: #8cce1e;
    background-color: #fff;
  }
}

@keyframes button-dot-outro {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1, 1);
  }
}

@keyframes button-ready-label {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 20px;
  }

  .contact-form {
    width: 100%;
  }

  .input-group {
    flex-direction: column;
    gap: 10px;
  }

  .contact-form button {
    width: 100%;
  }
}

/* Popup Styles */
.cd-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(94, 110, 141, 0.9);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}
.cd-popup.is-visible {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.cd-popup-container {
  position: relative;
  width: 90%;
  max-width: 400px;
  margin: 4em auto;
  background: #FFF;
  border-radius: .25em .25em .4em .4em;
  text-align: center;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-40px);
  transition-property: transform;
  transition-duration: 0.3s;
}
.cd-popup-container p {
  padding: 3em 1em;
}
.cd-popup-container .cd-buttons:after {
  content: "";
  display: table;
  clear: both;
}
.cd-popup-container .cd-buttons li {
  float: none;
  width: 100%;
  list-style: none;
}
.cd-popup-container .cd-buttons a {
  display: block;
  height: 60px;
  line-height: 60px;
  text-transform: uppercase;
  color: #FFF;
  transition: background-color 0.2s;
}
.cd-popup-container .cd-buttons li:first-child a {
  background: #8cce1e;
  border-radius: 0 0 .25em .25em;
}
.no-touch .cd-popup-container .cd-buttons li:first-child a:hover {
  background-color: #7fbf17;
}
.cd-popup-container .cd-buttons li:last-child a {
  background: #35a785;
  border-radius: 0 0 .25em .25em;
}
.no-touch .cd-popup-container .cd-buttons li:last-child a:hover {
  background-color: #2f9775;
}
.cd-popup-container .cd-popup-close {
  display: none; /* Remove the close button in the top right corner */
}
.cd-popup-container .cd-popup-close::before, .cd-popup-container .cd-popup-close::after {
  content: '';
  position: absolute;
  top: 12px;
  width: 14px;
  height: 3px;
  background-color: #acacad;
}
.cd-popup-container .cd-popup-close::before {
  transform: rotate(45deg);
  left: 8px;
}
.cd-popup-container .cd-popup-close::after {
  transform: rotate(-45deg);
  right: 8px;
}
.is-visible .cd-popup-container {
  transform: translateY(0);
}
@media only screen and (min-width: 1170px) {
  .cd-popup-container {
    margin: 8em auto;
  }
}

::-webkit-scrollbar {
  width: 15px;

}

::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #acacad;
  border-radius: 10px;
}