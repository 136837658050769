.projects {
    padding: 40px;
    background-color: #e0e0e0;
    font-family: 'Duke Charming Regular', serif;
    color: #008080;
    scroll-margin-top: 200px;
}

.projects h2 {
    text-align: center;
    margin-bottom: 20px;
}

.projects-content {
    text-align: justify;
}

.projects-content ul {
    list-style-type: disc;
    margin-left: 20px;
}

@media (max-width: 768px) {
    .projects {
        padding: 20px;
    }

    .projects h2 {
        font-size: 1.5rem;
    }

    .projects-content {
        font-size: 1.2rem;
    }
}
