.hero {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding: 40px;
    background-color: #000000;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.hero-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #1c1c1c;
    padding: 20px;
    border-radius: 10px;
    margin-right: 20px;
}

.hero-left img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 20px;
}

.hero-text {
    max-width: 600px;
    text-align: left;
    color: #adadac;
    font-family: 'Flamante Sans Bold', serif;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .hero {
        flex-direction: column;
        align-items: center;
    }

    .hero-left {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .hero-text {
        text-align: center;
        font-size: 1.2rem;
    }
}